.filters {
	.flags-header {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;

		i {
			margin-left: 5px;
		}
	}

	.btn-outline-success {
		margin-right: 15px;
	}

	.btn-outline-success:hover i,
	.btn-outline-info:hover i,
	.btn-outline-warning:hover i,
	.btn-outline-danger:hover i {
		color: white !important;
	}



	.btn-outline-success i,
	.btn-outline-danger i {
		margin-right: 5px;
	}

	.btn-outline-success i {
		color: #4dbd74;
	}

	.btn-outline-danger i {
		color: #f86c6b;
	}
}


.buttonsContainer {
	display: block;
	margin:  0 0 15px 0;
}